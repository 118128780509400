<template>
  <div>
    <MPModal
      :show.sync="showDeleteAccountModal"
      :showX="true"
      class="account-deletion-modal"
    >
      <DeleteAccount @delete-user="deleteUser" />
    </MPModal>
    <MPModal :show.sync="showThankYouForTryingModal" :showX="true">
      <ThankYouForTrying @close="closeThankYouForTrying" />
    </MPModal>
    <MPSpinner v-if="isBillingLoading" />
    <div v-else>
      <ActiveSubscription
        v-if="calculteState === 'active'"
        :title="billing.planTitle"
        :externalName="billing.planExternalName"
        :price="formattedPrice"
        :nextInvoice="formattedDate"
        :is-trial="billing.onTrial"
        @on-cancel="openRetention"
        @on-edit="openSubChange"
      />
      <GiftSubscription
        v-if="calculteState === 'gift'"
        :title="billing.planTitle"
        :externalName="billing.planExternalName"
        :nextInvoice="formattedDate"
        @on-subscribe="goToUpgrade"
      />
      <NotRenewingSubscription
        v-if="calculteState === 'notRenewing'"
        :title="billing.planTitle"
        :externalName="billing.planExternalName"
        :nextInvoice="formattedDate"
        :is-trial="billing.onTrial"
        @on-reactivate="goToUpgrade"
        @on-edit="openSubChange"
      />
      <CanceledSubscription
        v-if="calculteState === 'canceled'"
        @on-reactivate="goToUpgrade"
        @on-delete="showDeleteAccountModal = true"
      />
    </div>
  </div>
</template>

<script>
import checkoutApi from '@/api/checkout'
import DeleteAccount from './DeleteAccount'
import ThankYouForTrying from './ThankYouForTrying'
import { mapGetters, mapActions } from 'vuex'
import ActiveSubscription from './billing/ActiveSubscription.vue'
import GiftSubscription from './billing/GiftSubscription.vue'
import NotRenewingSubscription from './billing/NotRenewingSubscription.vue'
import CanceledSubscription from './billing/CanceledSubscription.vue'
import LoadingMixin from '@/mixins/LoadingMixin'
import MPSpinner from '@/components/common/MPSpinner.vue'
import ChargebeeMixin from '@/mixins/ChargebeeMixin'
import ProfitWell from '@/externalServices/ProfitWell'
import { EVENTS } from '@/events/analytics'
import moment from 'moment'

export default {
  name: 'SettingsBilling',
  mixins: [LoadingMixin, ChargebeeMixin],
  components: {
    MPSpinner,
    ActiveSubscription,
    NotRenewingSubscription,
    GiftSubscription,
    DeleteAccount,
    ThankYouForTrying,
    CanceledSubscription,
  },
  data() {
    return {
      showDeleteAccountModal: false,
      showThankYouForTryingModal: false,
    }
  },
  computed: {
    ...mapGetters({
      billing: 'user/billing',
      isBillingLoading: 'user/isBillingLoading',
      userIsCancelled: 'user/isCancelled',
      userIsExpired: 'user/isExpired',
    }),
    formattedDate() {
      return moment(this.billing.nextBillingDate).format('MMMM DD, YYYY')
    },
    formattedPrice() {
      if (!this.billing.nextInvoicePrice) {
        return ''
      }

      const stringNumber = this.billing.nextInvoicePrice.toString()
      const numberWithoutDecimals = stringNumber.substring(
        0,
        stringNumber.length - 2
      )
      const decimals = stringNumber.substring(stringNumber.length - 2)
      return new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
      }).format(parseFloat(numberWithoutDecimals + '.' + decimals))
    },
    calculteState() {
      if (this.userIsCancelled || this.userIsExpired) {
        return 'canceled'
      } else if (this.billing.isGift && this.billing.willRenew) {
        return 'active'
      } else if (this.billing.isGift) {
        return 'gift'
      } else if (this.billing.isCancelled) {
        return 'notRenewing'
      } else {
        return 'active'
      }
    },
  },
  methods: {
    goToUpgrade() {
      this.$router.push('/reactivate')
    },
    ...mapActions({
      refreshBilling: 'user/refreshBilling',
    }),
    async cancelSubscriptionClicked() {
      this.$analytics.track('mp settings cancel account clicked')
      try {
        const result = await ProfitWell.cancel(this.billing.subscriptionId)
        if (result) {
          this.cancelPlan(result)
        }
      } catch (err) {
        console.error(err)
      }
    },
    openSubChange() {
      this.changeSubscription({
        onSubChanged: () => this.refreshBilling(),
        onSourceAdded: () => this.refreshBilling(),
        onSourceUpdated: () => this.refreshBilling(),
        onSourceRemoved: () => this.refreshBilling(),
        onRemovedCancellation: () => this.refreshBilling(),
      })
    },
    deleteUser() {
      this.setLoading(true)
      return this.$store.dispatch('user/deleteUser').then(
        () => {
          this.showDeleteAccountModal = false
          this.setLoading(false)
          this.showThankYouForTryingModal = true
        },
        () => {
          this.setLoading(false)
        }
      )
    },
    closeThankYouForTrying() {
      location.reload()
    },
    intToMoney(quantity) {
      const stringNumber = quantity.toString()
      const numberWithoutDecimals = stringNumber.substring(
        0,
        stringNumber.length - 2
      )
      const decimals = stringNumber.substring(stringNumber.length - 2)
      const moneyFormat = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
      }).format(parseFloat(numberWithoutDecimals + '.' + decimals))

      return moneyFormat
    },
    async openRetention() {
      this.$posthog.sendEvent(EVENTS.subscription.initCancel)
      try {
        this.setLoading(true)
        const data = await checkoutApi.createCancel()
        const link = document.createElement('a')
        link.href = data.url
        link.click()
      } catch (error) {
        console.error(error.message)
        this.setLoading(false)
      }
    },
  },
  mounted() {
    this.$analytics.track('mp settings billing view')
  },
}
</script>

<style lang="scss">
.special-offer-modal {
  .modal-dialog {
    @include media-breakpoint-up(md) {
      max-width: 600px;
    }
  }

  .mp-modal-content {
    background-color: $white;
    @include media-breakpoint-up(md) {
      padding: 2rem;
    }
  }
}
.account-deletion-modal {
  .mp-modal-content {
    padding: 30px;
    @include media-breakpoint-down(sm) {
      padding: 15px;
      padding-top: 20px;
    }
  }
  .x-icon {
    @include media-breakpoint-down(sm) {
      top: 15px;
    }
  }
}
</style>
