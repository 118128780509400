<template>
  <div class="credential-wrapper">
    <CredentialsHeader>
      <template v-slot:left> </template>
      <template v-slot:center>
        <HeaderLogo />
      </template>
      <template v-slot:right>
        <ButtonNext
          id="go-to-plan"
          text="Show me my meal planner"
          class="next-button d-none d-md-block"
          @click="showPlan"
          data-spec="show-plan"
        />
      </template>
    </CredentialsHeader>
    <div class="welcome">
      <h1 class="welcome-title">You’re on your way</h1>
      <p class="welcome-text">Here’s what you need to know to get started</p>
      <div class="slider-container">
        <swiper
          ref="mySwiper"
          :options="swiperOptions"
          width="450"
          height="400"
        >
          <swiper-slide>
            <div class="row">
              <div class="slide-left col-12 col-lg">
                <picture>
                  <source
                    srcset="
                      @/assets/images/welcome/welcome-slide-4@2x.jpg,
                      @/assets/images/welcome/welcome-slide-4@2x.jpg 2x
                    "
                  />
                  <img
                    src="@/assets/images/welcome/welcome-slide-4@2x.jpg"
                    alt=""
                  />
                </picture>
              </div>
              <div class="slide-right col-12 col-lg text-serif">
                <div
                  class="slider-controls-container slider-controls-container--mobile d-lg-none"
                >
                  <SwiperNav
                    :activeSlide="activeSlide"
                    :slidesCount="slidesCount"
                    @prev-slide="swiper.slidePrev()"
                    @next-slide="swiper.slideNext()"
                  />
                </div>
                <div class="slide-right-content">
                  <h2 class="slide-title">Let’S talk recipes</h2>
                  <ol>
                    <li>
                      As a subscriber, you have access to the growing Recipe Box
                      of 3000+ whole-food, plant-based recipes, the majority of
                      which take less than 35 minutes to prepare.
                    </li>
                    <li>
                      With the handy search and filter features in your Recipe
                      Box, you can quickly and easily find the meals you’re
                      seeking - and then enjoy!
                    </li>
                  </ol>
                </div>
              </div>
            </div>
          </swiper-slide>
          <swiper-slide>
            <div class="row">
              <div class="slide-left col-12 col-lg">
                <picture>
                  <source
                    srcset="
                      @/assets/images/welcome/step-2.png,
                      @/assets/images/welcome/step-2.png 2x
                    "
                  />
                  <img src="@/assets/images/welcome/step-2.png" alt="" />
                </picture>
              </div>
              <div class="slide-right col-12 col-lg text-serif">
                <div
                  class="slider-controls-container slider-controls-container--mobile d-lg-none"
                >
                  <SwiperNav
                    :activeSlide="activeSlide"
                    :slidesCount="slidesCount"
                    @prev-slide="swiper.slidePrev()"
                    @next-slide="swiper.slideNext()"
                  />
                </div>
                <div class="slide-right-content">
                  <h2 class="slide-title">Meet your meal plans</h2>
                  <ol>
                    <li>
                      To kick things off, you’ll have your Weekly meal plans,
                      curated and released every Thursday from our team.
                    </li>
                    <li>
                      <strong
                        >Your Featured meal plans will showcase special menus,
                        and you can always create your own Custom meal plan from
                        scratch!</strong
                      >
                    </li>
                    <li>
                      <b>IMPORTANT</b>: Visit your Settings in the bottom left
                      corner of your account page (or bottom toolbar, via
                      mobile) to set your Preferences: serving sizes,
                      intolerances, scheduled meals and more!
                    </li>
                  </ol>
                </div>
              </div>
            </div>
          </swiper-slide>
          <swiper-slide>
            <div class="row">
              <div class="slide-left col-12 col-lg">
                <picture>
                  <source
                    srcset="
                      @/assets/images/welcome/welcome-step-3.png,
                      @/assets/images/welcome/welcome-step-3.png 2x
                    "
                  />
                  <img
                    src="@/assets/images/welcome/welcome-step-3.png"
                    alt=""
                  />
                </picture>
              </div>
              <div class="slide-right col-12 col-lg text-serif">
                <div
                  class="slider-controls-container slider-controls-container--mobile d-lg-none"
                >
                  <SwiperNav
                    :activeSlide="activeSlide"
                    :slidesCount="slidesCount"
                    @prev-slide="swiper.slidePrev()"
                    @next-slide="swiper.slideNext()"
                  />
                </div>
                <div class="slide-right-content">
                  <h2 class="slide-title">
                    SAVE TIME AND MONEY WITH SHOPPING LISTS AND MEAL PREP
                  </h2>
                  <ol>
                    <li>
                      As a member, you can explore your Grocery List feature and
                      get editable and printable lists -
                      <strong
                        >no more trips back to the store, or buying things you
                        already have!</strong
                      >
                    </li>
                    <li>
                      All members also receive meal prep guides customized to
                      their plan to
                      <strong>speed up cooking times through the week</strong>.
                    </li>
                  </ol>
                </div>
              </div>
            </div>
          </swiper-slide>
          <swiper-slide>
            <div class="row">
              <div class="slide-left col-12 col-lg">
                <Lottie
                  :options="lottieOptions[2]"
                  v-on:animCreated="(animation) => handleSlide(2, animation)"
                />
              </div>
              <div class="slide-right col-12 col-lg text-serif">
                <div
                  class="slider-controls-container slider-controls-container--mobile d-lg-none"
                >
                  <SwiperNav
                    :activeSlide="activeSlide"
                    :slidesCount="slidesCount"
                    @prev-slide="swiper.slidePrev()"
                    @next-slide="swiper.slideNext()"
                  />
                </div>
                <div class="slide-right-content">
                  <h2 class="slide-title">
                    PLEASE REACH OUT WITH ANY QUESTIONS - WE’RE HERE TO HELP
                  </h2>
                  <ol>
                    <li>
                      Tap the chat messenger bubble in the bottom right corner
                      to contact us, or click Settings > Contact Us.
                    </li>
                    <li>
                      You can also email
                      <a href="mailto:support@forksmealplanner.com"
                        ><strong>support@forksmealplanner.com</strong></a
                      >
                      or reply back to any email notification.
                    </li>
                    <li>
                      Most of the time we’ll get back to you within a few hours,
                      but we’ll always be in touch within 2 business days.
                    </li>
                  </ol>
                  <button
                    class="btn btn-link show-plan d-none d-md-flex"
                    @click="showPlan"
                  >
                    Show me my meal planner <ArrowBack class="arrow" />
                  </button>
                </div>
              </div>
            </div>
          </swiper-slide>
        </swiper>
      </div>
      <div class="slider-controls-container d-none d-lg-block">
        <SwiperNav
          :activeSlide="activeSlide"
          :slidesCount="slidesCount"
          @prev-slide="swiper.slidePrev()"
          @next-slide="swiper.slideNext()"
        />
      </div>
    </div>
    <div class="mobile-fixed-button d-md-none">
      <ButtonNext
        id="go-to-plan"
        text="Show me my meal planner"
        class="next-button btn"
        @click="showPlan"
      />
    </div>
  </div>
</template>

<script>
import CredentialsHeader from '@/components/credentials/CredentialsHeader'
import HeaderLogo from '@/components/credentials/HeaderLogo'
import ButtonNext from '@/components/credentials/register/ButtonNext'
import SwiperNav from '@/components/welcome/SwiperNav'
import { mapGetters } from 'vuex'
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import { ArrowBack } from '@/components/common/MPIcons'
import Lottie from 'vue-lottie'
import 'swiper/swiper.scss'
import slide1 from '@/assets/lotties/welcome/1.json'
import slide2 from '@/assets/lotties/welcome/2.json'
import slide3 from '@/assets/lotties/welcome/3.json'
import moment from 'moment'
import { events } from '@/utils/mixpanel.events'

const ANIMATION_START_DELAY = 1500

export default {
  components: {
    ButtonNext,
    CredentialsHeader,
    HeaderLogo,
    Swiper,
    SwiperSlide,
    ArrowBack,
    Lottie,
    SwiperNav,
  },
  computed: {
    ...mapGetters({
      creditCardRequired: 'headers/creditCardRequired',
      billing: 'user/billing',
    }),
    swiper() {
      return this.$refs.mySwiper.$swiper
    },
  },
  data() {
    return {
      activeSlide: 1,
      slidesCount: 4,
      swiperOptions: {
        autoHeight: false,
      },
      lottieOptions: [
        {
          animationData: slide1,
          autoplay: false,
        },
        {
          animationData: slide2,
          autoplay: false,
          loop: false,
        },
        {
          animationData: slide3,
          autoplay: false,
        },
      ],
    }
  },
  methods: {
    showPlan() {
      this.$analytics.track(events.account.showPlan.clicked)
      if (this.creditCardRequired) {
        this.$router.push({ path: '/reactivate', query: { back: '/plan' } })
      } else {
        this.$router.push('/plan')
      }
    },
    handleSlide(index, animation) {
      this.animations = this.animations || []
      this.animations[index] = animation
    },
  },
  mounted() {
    const priceString = this.billing.currentPrice.toString()
    const numberWithoutDecimals = priceString.substring(
      0,
      priceString.length - 2
    )
    const decimals = priceString.substring(priceString.length - 2)
    const nextDate = moment
      .duration(new Date(this.billing.nextBillingDate).getTime())
      .subtract(Date.now())

    window.fbq('track', 'Purchase', {
      value: parseFloat(numberWithoutDecimals + '.' + decimals),
      currency: 'USD',
      product_name: this.billing.planExternalName,
      duration: Math.ceil(nextDate.asMonths()),
    })

    if (this.animations[0]) {
      this.animations[0].goToAndStop(0)
      setTimeout(() => {
        this.animations[0].play()
      }, ANIMATION_START_DELAY)
    }
    this.swiper.on('init', (swiper) => {
      this.slidesCount = swiper.slides.length
    })
    this.swiper.on('slideChange', (swiper) => {
      this.activeSlide = swiper.activeIndex + 1
      const animation = this.animations[swiper.activeIndex]
      if (animation) {
        animation.goToAndStop(0)
        setTimeout(() => {
          animation.play()
        }, ANIMATION_START_DELAY)
      }
    })
  },
}
</script>

<style lang="scss" scoped>
.credential-wrapper {
  background-repeat: no-repeat;
  background-image: url('~@/assets/images/steaming-pod.svg');
  background-position: left 150px bottom -130px;
  background-size: 415px 394px;
  background-repeat: no-repeat;
  background-color: $clr-navy-3;
  @include media-breakpoint-down(sm) {
    padding-bottom: 90px;
    background-size: 178px 167px;
    background-position: left 75px bottom 30px;
  }
}

.credentials-header {
  border-bottom: 1px solid #ddd;
  ::v-deep .right {
    padding-right: 20px;
    padding-left: 0;

    @include media-breakpoint-down(md) {
      padding-right: 10px;
    }
  }
}

.welcome {
  text-align: center;
  max-width: 930px;
  margin: 0 auto;
  padding: 0 15px;

  @include media-breakpoint-down(md) {
    max-width: 490px;
  }

  @include media-breakpoint-down(xs) {
    max-width: 100%;
  }

  .welcome-title {
    font-size: rem(42);
    margin-top: 50px;

    @include media-breakpoint-down(md) {
      font-size: rem(36);
      line-height: 1.17;
    }

    @include media-breakpoint-down(sm) {
      line-height: 1.08;
      font-size: rem(24);
      margin-top: 20px;
    }
  }

  .welcome-text {
    font-size: rem(24);
    line-height: 1.17;
    font-weight: bold;
    margin: 14px auto 30px;
    max-width: 600px;

    @include media-breakpoint-down(md) {
      max-width: 490px;
      font-size: rem(18);
      line-height: 1.11;
    }

    @include media-breakpoint-down(xs) {
      margin-bottom: 0;
      line-height: 1.13;
      font-size: rem(16);
      max-width: 345px;
    }
  }
  .slide-left {
    @include media-breakpoint-down(md) {
      padding: 20px 20px 0;
      max-width: 100%;
    }
    @include media-breakpoint-up(lg) {
      padding-left: 0;
    }
  }

  .slide-right {
    padding-right: 40px;
    justify-content: center;
    align-content: center;
    display: flex;
    flex-direction: column;

    @include media-breakpoint-down(md) {
      padding: 0;
    }
  }

  .slide-right-content {
    margin: auto 0;
    padding: 15px 0;

    @include media-breakpoint-down(md) {
      padding: 24px 20px;
    }

    @include media-breakpoint-down(xs) {
      padding: 24px 0;
    }
  }

  .slide-title {
    font-weight: bold;
    letter-spacing: 0.5px;
    font-size: 1rem;
    text-transform: uppercase;
    text-align: left;
    line-height: 1.5;
    margin-bottom: 13px;

    @include media-breakpoint-down(md) {
      line-height: 1.5;
      text-align: center;
    }

    @include media-breakpoint-down(sm) {
      margin-bottom: 13px;
    }
  }

  .slider-container {
    $bullet-size: 21px;
    margin-top: 15px;
    position: relative;
    background-color: $white;
    border: 1px solid $clr-navy-5;

    @include media-breakpoint-down(xs) {
      background-color: transparent;
      border: 0;
      margin-top: 0;
    }

    .row {
      margin: 0;
    }

    ol {
      padding-left: 0;
      text-align: left;
      list-style: none;
    }
    ol li {
      counter-increment: slide-counter;
      margin-bottom: 20px;
      padding-left: $bullet-size + 10px;
      position: relative;
      letter-spacing: 0.5px;
      a {
        color: $body-color;
        text-decoration: underline;
        &:hover {
          text-decoration: none;
        }
      }
    }
    ol li::before {
      content: counter(slide-counter);
      width: $bullet-size;
      height: $bullet-size;
      text-align: center;
      border-radius: 50%;
      line-height: $bullet-size;
      background-color: $primary;
      color: $white;
      display: inline-block;
      margin-right: 5px;
      position: absolute;
      left: 0;
      top: 5px;
      font-family: $font-family-base;
      font-size: rem(13);
    }
  }

  .slider-button {
    $button-size: 39px;
    width: $button-size;
    height: $button-size;
    border-radius: 50%;
    background-color: $white;
    padding: 0;
    color: $body-color;
    border: 1px solid #b7c3c9;
    transition: all 0.2s linear;
    &:disabled {
      color: #b7c3c9;
      background-color: transparent;
    }
    &--prev {
      svg {
        transform: rotate(180deg);
      }
    }
  }
  .slide-numbers {
    margin: 0 20px;
  }

  .slide-number--current {
    font-weight: bold;
  }

  .slider-controls-container {
    margin-top: 25px;
  }

  .slider-controls-container--mobile {
    margin: 0;
  }

  .create-account-header {
    .title {
      padding-top: 1rem;
    }
  }
}

.show-plan {
  text-transform: uppercase;
  font-family: $font-family-base;
  font-size: rem(18);
  font-weight: bold;
  padding: 0;
  display: flex;
  align-self: flex-start;
  padding-left: 30px;
  @include media-breakpoint-down(md) {
    align-self: center;
    margin: 0 auto;
  }
  .arrow {
    transform: rotate(180deg);
    margin-left: 8px;
    width: 25px;
    height: 25px;
  }
}

.next-button {
  max-width: 300px;

  @include media-breakpoint-down(md) {
    font-size: 13px;
    max-width: 250px;
  }
}

.mobile-fixed-button {
  padding: 15px 15px 20px;
  background-color: $white;
  border-top: 1px solid #ddd;
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: 1;

  .next-button {
    width: 100%;
    max-width: 100%;
  }
}

@include media-breakpoint-up(md) {
  .swiper-slide {
    min-height: 430px;
    display: flex;
    align-items: center;
  }
}
</style>
