<template>
  <MPModal :show.sync="showModal" :show-x="true" id="nutritional-modal">
    <div class="px-2 px-md-4 py-md-4 py-5">
      <div class="text-left">
        <h4 class="text-center">NUTRITIONAL INFORMATION</h4>
        <div v-if="suggestedServing">
          <div>
            Suggested serving <span class="text-muted">(per adult)</span>
          </div>
          <span class="text-muted" v-html="suggestedServing"></span>
          <hr />
        </div>

        <div>Nutritional analysis per serving</div>
        <span class="text-muted" v-if="data && data.hasInfo">
          <span v-for="item in data.data" :key="item.label">
            {{ item.label }}: {{ item.text }};
          </span>
        </span>
        <span class="text-muted" v-if="data && !data.hasInfo">
          {{ data.message }}
        </span>
        <hr />

        <p class="nutritional-note text-muted small">
          Note: Nutritional information is provided as an estimate only.
        </p>
      </div>
    </div>
  </MPModal>
</template>

<script>
export default {
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    data: {
      type: Object,
    },
    suggestedServing: {
      type: String,
    },
  },
  computed: {
    showModal: {
      get() {
        return this.show
      },
      set(value) {
        this.$emit('update:show', value)
      },
    },
  },
}
</script>
