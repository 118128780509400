import { render, staticRenderFns } from "./ActionServings.vue?vue&type=template&id=16613d65&scoped=true"
import script from "./ActionServings.vue?vue&type=script&lang=js"
export * from "./ActionServings.vue?vue&type=script&lang=js"
import style0 from "./ActionServings.vue?vue&type=style&index=0&id=16613d65&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "16613d65",
  null
  
)

export default component.exports